export const menu = [
  {
    title: 'Home',
    path: '/',
  },
  {
    title: 'Eventos y Talleres',
    path: '/eventos',
  },
  {
    title: 'Blog',
    path: '/blog',
  },
  {
    title: 'Podcast',
    path: '/podcast',
  },
  {
    title: 'Miembros',
    path: '/miembros',
  },
  // {
  //   title: 'Contacto',
  //   path: '/contacto',
  // },
];
export const menu2 = [
  {
    title: 'Contacto',
    path: '/contacto',
  },
]