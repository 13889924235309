import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';


import LogoStyles from '../styles/LogoStyles';

function Logo() {
  return (
    <LogoStyles to="/">
      {/* <StaticImage
              className="logo"
              src="../images/icon.png"
              alt="Lee up Icon"
              placeholder="blurred"
              // objectPosition="50% 30%"
            />
         */}
      <svg id="Capa_1" fill='currentColor' data-name="Capa 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 857.72 508.22">
        <path d="M607.2,59.3a1.28,1.28,0,0,0,.6-1.9l-7-14.6a1.14,1.14,0,0,1,.5-1.5,1.09,1.09,0,0,1,.5-.1l52.7,3.3a1.86,1.86,0,0,1,1.7,1.9,1.61,1.61,0,0,1-.3.9L627,90.4a1.81,1.81,0,0,1-2.6.5,1.85,1.85,0,0,1-.7-.8L618,78.3a1.06,1.06,0,0,0-1.5-.5h0L479.8,146.5a5,5,0,0,1-3.8.3l-70.5-23.1a.61.61,0,0,0-.6.1l-82.5,61.9a2,2,0,0,1-1.1.3l-78.7-10a1.83,1.83,0,0,1-1.6-1.8V157.5a1.69,1.69,0,0,1,1.7-1.7.9.9,0,0,1,.5.1,29.41,29.41,0,0,0,5.9,1.2c19.2,2.3,38.3,4.5,57.5,6.7a69.21,69.21,0,0,0,9,1.1,1.69,1.69,0,0,0,1-.3l82.6-62.4a2.22,2.22,0,0,1,1.6-.3c23.8,7.4,47.5,15.1,71.3,22.8a6.88,6.88,0,0,0,5.3-.1C521.1,102.9,564.3,81,607.2,59.3Z" transform="translate(-22.35 -41.2)"/>
        <path d="M548.5,278.2a.75.75,0,0,1-.5-.7V128.2a1.78,1.78,0,0,1,1-1.6L608,97a1.17,1.17,0,0,1,1.6.5c.1.1.1.2.1.4a7.12,7.12,0,0,1,.1,1.6q.3,89.1-.1,177.3a2,2,0,0,1-2,2c-19.4,0-38.5,0-57.2-.1A11.53,11.53,0,0,1,548.5,278.2Z" transform="translate(-22.35 -41.2)"/>
        <path d="M343.5,277.8V187a1.23,1.23,0,0,1,.5-1l59.3-44.8a1.18,1.18,0,0,1,1.7.3,1,1,0,0,1,.2.7l.2,135.5a1.22,1.22,0,0,1-1.2,1.2H344.7A1.13,1.13,0,0,1,343.5,277.8Z" transform="translate(-22.35 -41.2)"/>
        <path d="M445.9,177.7c.1-8.8.1-17.4,0-25.6,0-1.1.5-1.5,1.6-1.2l31,9.8a1,1,0,0,0,.9-.1l25.9-12.8a1.89,1.89,0,0,1,2.6.9,2.92,2.92,0,0,1,.2.9l-.3,128.2a1.22,1.22,0,0,1-1.2,1.2H446.9a.53.53,0,0,1-.6-.6Q445.4,228,445.9,177.7Z" transform="translate(-22.35 -41.2)"/>
        <path d="M303,198.7v78.8a1.54,1.54,0,0,1-1.5,1.5H242.9a1.54,1.54,0,0,1-1.5-1.5h0l.5-85.9a1.54,1.54,0,0,1,1.5-1.5h.2l58.1,7.1A1.51,1.51,0,0,1,303,198.7Z" transform="translate(-22.35 -41.2)"/>
        <path d="M119.5,496.6c29.4,11.8,67.4,4.2,86.6-22.1a1.4,1.4,0,0,1,1.9-.3,1.79,1.79,0,0,1,.5.7c4.3,14,9.1,28,14.5,41.8a2.54,2.54,0,0,1-.5,3c-21.6,21.8-52.1,29.2-82.3,29.4a126.14,126.14,0,0,1-45.8-8.4c-62.5-25-85-97.4-64.9-157.7C52,315.4,122,292.9,186,312.8a91.89,91.89,0,0,1,35.5,21c1.6,1.5,2.1,2.1,1.2,4.4-5.3,13.7-10.2,27.7-14.5,42a1.09,1.09,0,0,1-1.2.7,1,1,0,0,1-.5-.4c-13-16.9-30.5-26.1-52.3-27.5-54.5-3.5-82.6,38.7-75.8,88.6C81.8,466.9,95.7,487,119.5,496.6Z" transform="translate(-22.35 -41.2)"/>
        <path d="M298.2,309a1.63,1.63,0,0,1,.9.4.76.76,0,0,1,.2.5c0,.2,0,.4-.2.5A51.1,51.1,0,0,0,292,335V521.4a67.91,67.91,0,0,0,1.8,11.3,24.79,24.79,0,0,0,6.1,11.8.3.3,0,0,1,0,.4.35.35,0,0,1-.2.1H229.5c-.3,0-.4-.1-.2-.4,6.8-10.1,7.7-24.2,7.7-35.9q.15-119.25,0-160.6c0-12.7-.6-26.7-7.9-38.3-.1-.1,0-.3.1-.4h.1Z" transform="translate(-22.35 -41.2)"/>
        <path d="M374,356.6v61.5c0,2.4-.7,6.2.1,9.4.1.6.3.6.6.1,14.2-31.1,51.6-44,83-32.2,3.6,1.4,11.3,5.3,11.3,9.8v51.1a.86.86,0,0,1-.8.8c-.2,0-.3-.1-.5-.2-23.1-17.5-55.9-17.5-81.9-7.4-5.2,2-11.7,6.5-11.8,12.6-.5,23.4-.5,43.3,0,59.9.2,7.4,2.3,16.2,7.3,22.4q.45.6-.3.6H311a.22.22,0,0,1-.2-.2v-.1c7.4-9.9,7.9-25.5,7.9-37.5q-.15-82.8-.6-165.6c-.1-12.4-1.2-21.5-6.9-31.1-.6-.9-.3-1.4.8-1.4H487.7a1.54,1.54,0,0,1,1.5,1.5v57.7a.65.65,0,0,1-.6.6.6.6,0,0,1-.4-.1c-13.7-10.9-29.1-12.6-46.1-12.7-22.5-.1-45.1-.1-67.6,0C374.2,356,374,356.2,374,356.6Z" transform="translate(-22.35 -41.2)"/>
        <path d="M554.1,543.7c-22.6-7-40.5-25.2-46-48.1-2.3-9.3-3.1-19.3-3.1-29.9q0-63.75-.2-127.4c0-9.6-1.9-20.5-7.8-28.4a.62.62,0,0,1,.1-.8.37.37,0,0,1,.3-.1h69.8a.79.79,0,0,1,.8.8.6.6,0,0,1-.1.4c-6.2,9.9-7.9,22.3-7.9,33.9V457.6c0,3.9.6,7.7,1.1,12.6,1.1,12.6,7,24.9,20.5,27.8,9.5,2,21.2,1.7,29.1-3.5,14-9.1,12.6-32.6,12.7-47.3.2-30.9-.6-62.6-.4-100.9,0-11.7-.3-26.2-7.7-35.8a.77.77,0,0,1,.1-1,.37.37,0,0,1,.3-.1h.5c19.5-.2,39-.1,58.5.4a85.83,85.83,0,0,0,10.1-.3.77.77,0,0,1,.8.7c0,.2,0,.4-.2.5-6.5,8.1-7.5,22.2-7.5,32.2V471.3a96.49,96.49,0,0,1-3.6,26C659.8,549,597.7,557.1,554.1,543.7Z" transform="translate(-22.35 -41.2)"/>
        <path d="M758.7,425.4c-.1-.2-.4-.4-.6-.3s-.3.2-.3.4c0,30-.1,59.8-.1,89.4,0,9.1,1.6,22,7.6,29.4a.45.45,0,0,1,0,.5.37.37,0,0,1-.3.1H695.5c-.4,0-.4-.1-.2-.5,7.4-10.8,7.7-24.6,7.7-38.1q0-78.9-.1-157.9c0-12.7.1-27.3-7.8-37.9a1.08,1.08,0,0,1,.2-1.4,1.42,1.42,0,0,1,.6-.2h81.7c17.2,0,36.2,2.2,53,9.6,41.4,18.4,57,59.5,46.1,102.5-5.8,22.7-20,42.9-40.4,54.4-13.3,7.4-36.8,14.5-51.9,7.7a1.56,1.56,0,0,1-.8-.8Zm.1-9.1c3.9,9.5,11.6,19.1,22,21.7,17.9,4.5,33.1-2.7,40.1-19.2,10.2-24,3.3-55-24.9-61.6-10.2-2.4-25.4-1.2-37.4-1.2-.4,0-.5.2-.5.5.1,18,0,36-.2,54A12.24,12.24,0,0,0,758.8,416.3Z" transform="translate(-22.35 -41.2)"/>
      </svg>
    </LogoStyles>
  );
}

export default Logo;
